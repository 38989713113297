export const AWS_CONFIG = {
  Auth: {

    // (required)- Amazon Cognito Region
    region: 'us-east-1',

    // (optional) - Amazon Cognito User Pool ID
    userPoolId: 'us-east-1_4QrKkRTe3',

    // (optional) - Amazon Cognito Web Client ID (26-char alphanumeric string, App client secret needs to be disabled)
    userPoolWebClientId: '59aqqihgs3i1tcoq077ej8b8im',

    mandatorySignIn: true,

    // (optional) - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    cookieStorage: {
      // - Cookie domain (only required if cookieStorage is provided)
      domain: '.yesoutdoor.nl',
      // (optional) - Cookie path
      path: '/',
      // (optional) - Cookie expiration in days
      expires: 365,
      // (optional) - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
      sameSite: 'lax',
      // (optional) - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      secure: true
    },

    // (optional) - Hosted UI configuration
    oauth: {
      domain: 'https://account.yesoutdoor.nl',
      scope: [
        'phone',
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin'
      ],
      redirectSignIn: 'https://admin.yesoutdoor.nl/dashboard',
      redirectSignOut: 'https://admin.yesoutdoor.nl/logout',
      clientId: '59aqqihgs3i1tcoq077ej8b8im',
      responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
  }
};
